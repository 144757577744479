import React from 'react'
import './style.scss'
import title_icon from '../../images/prumdrt2020/title_icon.png'
export default ({ title, style, local, hasIcon = true }) => {
  return (
    <>
      <div
        className={`SectionTitle ${hasIcon ? '' : 'hideIcon'}`}
        style={style}
      >
        {hasIcon && <img className="title-icon" src={title_icon} alt="" />}

        <div className={`title-bg ${local}`}>{title}</div>
      </div>
    </>
  )
}
