import React from 'react'
import Popup from 'reactjs-popup'
import parse from 'html-react-parser'
import close_btn from '../../images/prumdrt2020/close_btn.svg'
import red_symbol from '../../images/prumdrt2020/red_symbol.svg'

import './styles.scss'
const SpeakerPopup = ({
  open,
  selectedId,
  onCloseModal,
  speakerData,
  customClass,
  labels,
  locale
}) => {
  const speakers = speakerData
  let speakerDetails = speakers.find(item => item.id === selectedId)

  if (!speakerDetails) {
    speakerDetails = {
      name: 'Xie Jing',
      avatar: '',
      country: 'China',
      topic: 'How to Improve Sales Performance through Brand Marketing',
      job_title: 'Top Creativity Keynote Speaker',
      description:
        'Inspired by her husband who is also a Prudential agent, Xie Jing joined the Prudential team in 2010. She devotes her time and effort to helping people to achieve their core targets and dreams. She won the top Excellence Club award in her first year and has been a MDRT achiever and TOT Member for the 8 years since then.'
    }
  }

  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={onCloseModal}
      contentStyle={{
        width: '95%',
        maxWidth: 1732,
        borderRadius: 12
      }}
      overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      className="speaker-popup"
      modal
      lockScroll
      repositionOnResize
    >
      <div className={`speaker-popup ${customClass}`}>
        <div className="speaker-popup-container">
          <img src={close_btn} className="close-btn" onClick={onCloseModal} />
          <img src={red_symbol} className="red-symbol" alt="" />
          <div className="speaker-avatar">
            <img src={speakerDetails.avatar} />
          </div>
          <div className="speaker-details">
            <div className="speaker-name">
              {speakerDetails.name}
              <div className="red-line-under-name"></div>
            </div>
            <div className={`speaker-info-row title-job ${locale}`} >
              <p>{parse(speakerDetails.job_title)}</p>
            </div>
            <div className={`speaker-info-row mdrt-tot-cot ${locale}`} >
              <p>{speakerDetails.mdrt_tot_cot}</p>
            </div>
            <div className={`speaker-info-row ${locale}`}>
              <p>{labels.topic}:</p>
              <p>{parse(speakerDetails.topic)}</p>
            </div>
            <div className={`speaker-description ${locale}`}>
              <p className="text-normal">{parse(speakerDetails.description)}</p>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default SpeakerPopup
