import React from 'react'
import './styles.scss'
import red_symbol from '../../images/prumdrt2020/right_red_symbol.png'

export default ({ speaker, style = {}, isBreakout = false, onClick }) => {
  if (isBreakout) {
    return (
      <div
        className="MainSpeaker breakout-speaker"
        style={style}
        onClick={onClick}
      >
        <div className="speaker-avatar">
          <img src={speaker.avatar} />
        </div>
        <div className="speaker-name">
          <p>{speaker.name}</p>
        </div>
      </div>
    )
  }
  return (
    <div className="MainSpeaker" style={style} onClick={onClick}>
      <div className="speaker-name-wrapper">
        <div className="speaker-name">
          <p>{speaker.name}</p>
        </div>
        <img src={red_symbol} className="red-symbol" alt="" />
      </div>
      <div className="speaker-avatar">
        <img src={speaker.avatar} />
      </div>
    </div>
  )
}
