import React, { useState } from 'react'
import SectionTitle from 'src/components/SectionTitle'
import MainSpeaker from 'src/components/MainSpeaker'
import RedLine from '../RedLine'
import SpeakerPopup from '../SpeakerPopup'

import './styles.scss'

export default ({ customClass = '', data, listTitle, locale, labels }) => {
  const [selectedId, setSelectedId] = useState(-1)
  const speakers = data

  return (
    <div className={`speakers-list-wrapper ${customClass}`}>
      <RedLine
        containerStyles={{ justifyContent: 'flex-start' }}
        styles={{ height: 15, width: '15%' }}
      />
      <SectionTitle title={listTitle} local={locale} />
      <div className="speakers-list">
        {speakers.map((speaker, index) => {
          const have_bio = speaker.description

          return (
            <MainSpeaker
              speaker={speaker}
              key={index}
              onClick={() => {
                if (have_bio) {
                  setSelectedId(speaker.id)
                }
              }}
              style={{ zIndex: index, cursor: have_bio ? 'pointer' : '' }}
            />
          )
        })}
      </div>
      <RedLine />
      <SpeakerPopup
        open={selectedId !== -1}
        onCloseModal={() => setSelectedId(-1)}
        selectedId={selectedId}
        speakerData={speakers}
        customClass="platform"
        labels={labels}
        locale={locale}
      ></SpeakerPopup>
    </div>
  )
}
